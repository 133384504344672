import { createSlice, PayloadAction } from "@reduxjs/toolkit";
/**Not in use */

interface MenuState {
  permissions: {};
  accountId:''
  status: 0
}

const initialState: MenuState = {
  permissions: {},
  accountId:'',
  status: 0
};

const permissionSlice = createSlice({
  name: "Permissions",
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<any>) => {
      state.permissions = action.payload;
    },
    setPermissionStatus: (state, action: PayloadAction<any>) => {
      state.status = action.payload;
    },
    setAccountId: (state, action: PayloadAction<any>) => {
      state.accountId = action.payload;
    },
  },
});

export const { setPermissions, setPermissionStatus, setAccountId } = permissionSlice.actions;
export default permissionSlice.reducer;
