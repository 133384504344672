import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  wallb_data: [];
}

const initialState: MenuState = {
  wallb_data: [],
};

const wallboardSettingsSlice = createSlice({
  name: "wallboardSettingsData",
  initialState,
  reducers: {
    setWallboardData: (state, action: PayloadAction<any>) => {
      state.wallb_data = action.payload;
      console.log(state, "state");
    },
  },
});

export const { setWallboardData } = wallboardSettingsSlice.actions;
export default wallboardSettingsSlice.reducer;
