import { useEffect, useState, useRef } from "react";
import { useApp } from "../../../../appContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useDispatch, useSelector } from "react-redux";
import "../../../../App.css";
import { activeProvision } from "../../../../toolkit/reducers/provisionSlice";
import { setBreadcrumbs } from "../../../../toolkit/reducers/breadCrumbsSlice";
import { LeftMenuItems } from "../../../../config/leftMenuItems";
import { hasPermission } from "../../../../services/utilities";

const BrudCrubs = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigation = useLocation();
  const activeBreadCrumbs = useSelector(
    (state: any) => state.BreadCrumbsList.brudCrubName
  );
  const permissionsList = useSelector(
    (state: any) => state.Permissions.permissions
  );
  const displayNames = [
    { key: "callrecordings", displayName: "Call Recordings" },
    { key: "callsinprogress", displayName: "Calls in Progress" },
    { key: "callarchives", displayName: "Call Archives" },
    { key: "manageextension", displayName: "Manage Extension" },
    { key: "legacycallrecordings", displayName: "legacy Call Recordings" },
    { key: "callReports", displayName: "Call Reports" },
    { key: "callstatistics", displayName: "Call Statistics" },
    { key: "aboutreporting", displayName: "About Reporting" },
    { key: "displaygroups", displayName: "Display Groups" },
    { key: "legacycallreports", displayName: "Legacy Call Reports" },
    { key: "callrecordings", displayName: "Call Recording" },
    { key: "callrecordings", displayName: "Call Recording" },
    { key: "provisionuser", displayName: "Provision User" },
    { key: "addTenant", displayName: "Add Tenant" },
    { key: "viewplan", displayName: " View DCID Plan" },
    { key: "newplan", displayName: "New DCID Plan" },
    { key: "ratecenter", displayName: "Rate Center" },
    { key: "dids", displayName: "DIDs" },
    { key: "dcid", displayName: "DCID" },
    { key: "callStatistics", displayName: "Call Statistics" },
    { key: "topcallers", displayName: "Top Callers" },
    { key: "topanswers", displayName: "Top Answers" },
    { key: "topextensions", displayName: "Top Extensions" },
    { key: "topnumbers", displayName: "Top Numbers" },
    { key: "missedcalls", displayName: "Missed Calls" },
    { key: "busiesthour", displayName: "Busiest Hour" },
    { key: "busiestday", displayName: "Busiest Day" },
    { key: "liveboards", displayName: "Live Boards" },
    { key: "userpanel", displayName: "User Panel" },
    { key: "queuemonitor", displayName: "Queue Monitor" },
    { key: "scrubtool", displayName: "Scrub Tool" },
    { key: "activecalls", displayName: "Active Calls" },
    { key: "sms", displayName: "SMS" },
    { key: "smsusers", displayName: "SMS Users" },
    { key: "newgroup", displayName: "New Group" },
    { key: "wallboardsettings", displayName: "Wallboard Settings" },
  ];
  var temp: any = [];
  var data = LeftMenuItems["control"].map((x) => ({ ...x }));
  data.map((node: any) => {
    if (node?.childrens) {
      var child: any = [];
      node?.childrens.map((item: any) => {
        if (hasPermission(node.item, item.permission, permissionsList)) {
          child.push(item);
        }
      });
      node.childrens = child;
      if (child.length) {
        temp.push(node);
      }
    } else if (hasPermission(node.item, node.permission, permissionsList)) {
      temp.push(node);
    }
  });
  const navigateTo = (nav: any, index: number) => {
    // console.log("navnavnav", index)
    // debugger
    if (activeBreadCrumbs.length - 1 !== index) {
      if (index === 0) {
        var hPath = temp.find((item: any) => {
          return item.path === nav.path;
        });
      }
      var path = nav.path;
      if (hPath && hPath?.childrens?.length) {
        path = nav.path + "/" + hPath.childrens[0].path;
      }
      // nav.path
      navigate(`/app/${path}`, { replace: true });
    }
  };
  useEffect(() => {
    let baseURL: Array<any> = navigation.pathname.split("/").filter((node) => {
      return node !== "" && node !== "app";
    });
    // console.log(baseURL, "baseURL");
    let Crumb: any = [];
    baseURL.map((node: any, index: number) => {
      if (node !== "" && node !== "app") {
        var path = "";
        if (index === 0) {
          const res = LeftMenuItems.control.find((left: any) => {
            return left.item.replace(/_/g, "") === node;
          });
          if (res) {
            path = res.path;
          }
        } else if (index === 1) {
          path = `${baseURL[index - 1]}/${node}`;
        } else if (index === 2) {
          path = `${baseURL[index - 1]}/${node}`;
        }
        if (path) {
          Crumb.push({ display: node, path: path });
        }
      }
    });
    if (baseURL[1] !== "DID" || baseURL.length === 2) {
      dispatch(setBreadcrumbs(Crumb));
    }
    // if(baseURL.length < 3){
    // }
  }, [navigation.pathname]);
  const checkbrudcrumns = useSelector(
    (state: any) => state.BreadCrumbsList.brudCrubName
  );
  console.log("checkbrudcrumns", checkbrudcrumns);

  return (
    <div>
      <span className=" flex  items-center w-full m-[1px] -space-x-[4px] leading-4 font-medium text-center text-gray-500 bg-[#F8F8F8] p-1  px-5">
        {activeBreadCrumbs
          .filter((node: any) => {
            return node.display !== "";
          })
          .map((brudcrub: any, index: number) => {
            return (
              <span
                key={index}
                onClick={() => navigateTo(brudcrub, index)}
                //
                className={`${
                  index + 1 === activeBreadCrumbs.length
                    ? "bg-[#FFEBD5] text-[#000000]  rounded-l-[0px]"
                    : "bg-[#fff]  text-[#595754] rounded-r-[0px]"
                } flex relative py-1 ml-0 mr-0 px-4    rounded-[20px] capitalize items-center text-[12px]  font-normal cursor-pointer`}
              >
                {/* {brudcrub.display} */}
                {/* {console.log(brudcrub, "bbbbbb")}
                {console.log(
                  displayNames?.filter((node) => {
                    return node?.key === brudcrub?.display ? node : "wrong";
                  }),
                  "sdf"
                )} */}
                {/* {displayNames?.filter((node) => {
                  return node?.key === brudcrub?.display
                    ? displayNames[0].displayName
                    : "wrong";
                })} */}
                {displayNames?.filter((node) => {
                  return node?.key === brudcrub?.display;
                }).length === 0
                  ? brudcrub?.display
                  : displayNames?.filter((node) => {
                      return node?.key === brudcrub?.display;
                    })[0]?.displayName}
              </span>
            );
          })}
      </span>
    </div>
  );
};
export default BrudCrubs;
