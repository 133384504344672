import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Add_Dcid_Plan from "./add_Dcid_Plan";
import View_Dcid_Plan from "./view_Dcid_Plan";
import Rate_Center from "./rate_Center";
import Delete_Dcid_Modal from "./delete_Dcid_Modal";

function Dcid_Router() {
  const path = useLocation();
  return (
    <>
      <Routes>
        <Route
          path="/newplan"
          element={
            <React.Suspense fallback={<></>}>
              <Add_Dcid_Plan />
            </React.Suspense>
          }
        />
        {/* <Route
          path="/viewplan"
          element={
            <React.Suspense fallback={<></>}>
              <View_Dcid_Plan />
            </React.Suspense>
          }
        /> */}
        <Route
          path="/:id/viewplan"
          element={
            <React.Suspense fallback={<></>}>
              <View_Dcid_Plan />
            </React.Suspense>
          }
        />
        <Route
          path="/:id/delete"
          element={
            <React.Suspense fallback={<></>}>
              <Delete_Dcid_Modal />
            </React.Suspense>
          }
        />
        <Route
          path="/newplan/ratecenter"
          element={
            <React.Suspense fallback={<></>}>
              <Rate_Center />
            </React.Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default Dcid_Router;
