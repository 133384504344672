import React, { useState, useEffect } from "react";

// Helper function to calculate duration from startTime
const calculateDuration = (startTime) => {
  if (!startTime) {
    return "Invalid start time"; // Safe fallback
  }

  let startDate;

  try {
    if (typeof startTime === "string") {
      startDate = new Date(startTime); // If ISO 8601 string
    } else if (typeof startTime === "number") {
      // Check if timestamp is in milliseconds (more than 1 trillion)
      startDate =
        startTime > 1000000000000
          ? new Date(startTime)
          : new Date(startTime * 1000); // Convert seconds to milliseconds
    } else {
      throw new Error("Invalid startTime format");
    }

    if (isNaN(startDate.getTime())) {
      throw new Error("Invalid Date");
    }
  } catch (error) {
    console.error("Error parsing startTime:", error.message);
    return "Invalid start time";
  }

  const now = new Date();
  const diff = now.getTime() - startDate.getTime(); // Duration in milliseconds

  if (diff < 0) {
    return "0h 0m 0s"; // Prevent negative durations
  }

  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return `${hours}h ${minutes}m ${seconds}s`;
};

// Component to display duration
const DurationCounter = ({ startTime }) => {
  const [duration, setDuration] = useState("0h 0m 0s");

  useEffect(() => {
    // Validate the initial `startTime`
    if (!startTime) {
      console.warn("Invalid or missing startTime prop");
      return;
    }

    // Set duration immediately and update every second
    setDuration(calculateDuration(startTime));

    const intervalId = setInterval(() => {
      setDuration(calculateDuration(startTime));
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [startTime]);

  return (
    <div>
      <p>{duration}</p>
    </div>
  );
};

export default DurationCounter;
