// store.ts
import { configureStore, current } from "@reduxjs/toolkit";
import counterSlice from "./reducers/counterSlice";
import authReducer from "./reducers/authSlice";
import numberManagerReducer from "./reducers/numberManagerSlice";
import breadCrumbsReducer from "./reducers/breadCrumbsSlice";
import callRecordingsSlice from "./reducers/callRecordingsSlice";
import activeMenuSlice from "./reducers/activeMenuSlice";
import liveBoardsSlice from "./reducers/liveBoardsSlice";
import reportsSlice from "./reducers/reportsSlice";
import provisionSlice from "./reducers/provisionSlice";
import permissionSlice from "./reducers/permissionSlice";
import tenantsListSlice from "./reducers/tenantsListSlice";
import tableButton1IndexSlice from "./reducers/tableButton1IndexSlice";
import breadCrumbsSlice from "./reducers/breadCrumbsSlice";
import activeSubMenuSlice from "./reducers/activeSubMenuSlice";
import provisonedUserSlice from "./reducers/provisionedUserDataSlice";
import SimulationIdSlice from "./reducers/SimulationIdSlice";
import userDataSlice from "./reducers/userDataSlice";
import activeTenantSlice from "./reducers/activeTenantSlice";
import launchDateSlice from "./reducers/launchDateSlice";
import scrubListDataSlice from "./reducers/scrubListDataSlice";
import wallboardSettingsSlice from "./reducers/wallboardSettingsSlice";

const store = configureStore({
  reducer: {
    counter: counterSlice,
    auth: authReducer,
    numberManager: numberManagerReducer,
    BreadCrumbs: breadCrumbsReducer,
    BreadCrumbsList: breadCrumbsSlice,
    Call: callRecordingsSlice,
    menuItem: activeMenuSlice,
    ActiveBoard: liveBoardsSlice,
    ActiveReport: reportsSlice,
    activeProvision: provisionSlice,
    Simulation: SimulationIdSlice,
    Permissions: permissionSlice,
    tenants: tenantsListSlice,
    button1Index: tableButton1IndexSlice,
    setActiveSubMenu: activeSubMenuSlice,
    provisionedData: provisonedUserSlice,
    currentUserData: userDataSlice,
    TenantData: activeTenantSlice,
    launchDateTime: launchDateSlice,
    listData: scrubListDataSlice,
    wallboardSettingsData: wallboardSettingsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
