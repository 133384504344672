export const development = {
    // faro: {
    //     // url: "https://grafana.kanimango.com/agent2",
    //     // apiKey: "secret",
    //     url: "https://vaspian-dev.netstratum.com/collect",
    //     apiKey: "eY7WbRKC",
    //     app: {
    //         name: "test",
    //         version: "1.0.0",
    //     },
    // }
    faro: {
        url: "https://vaspian-test.vaspian.com/collect",
        apiKey: "eY7WbRKC",
        // url: "https://vaspian-dev.netstratum.com/collect",
        // apiKey: "eY7WbRKC",
        app: {
            name: "vaspian",
            version: "1.0.0",
        },
    }
}