import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
  activeMenu: number;
}

const initialState: CallState = {
  activeMenu: 0,
};

const reports = createSlice({
  name: "ActiveReport", // Use a different name for the slice
  initialState,
  reducers: {
    ActiveReport: (state, action: PayloadAction<number>) => {
      state.activeMenu = action.payload;
    },
  },
});

export const { ActiveReport } = reports.actions;
export default reports.reducer;
