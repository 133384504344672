import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import "./dropdown.css";

const Dropdown = (props) => {
  const { t } = useTranslation();

  const { node, data, change, index, handleDDChange } = props;
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  };
  const setChange = (value) => {
    let updateData = [...data];
    let filter;
    filter = updateData.filter((n) => {
      return n.name === node.name;
    });
    if (filter[0]) {
      filter[0].value = value;
      filter[0].error = false;
    }
    if (index) {
      change([...updateData], index);
    } else {
      change([...updateData]);
    }

    if (node?.Callback) {
      console.log("nodeee", node);
      node.Callback(value);
    }
    if (handleDDChange) {
      handleDDChange(index, value);
    }
  };

  useEffect(() => {
    if (node.sort === true) {
      Sorting();
    }
  }, []);

  function Sorting() {
    node.childrens.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }
  return (
    <div className="input">
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">
          {t(node.title) + (node.required ? "* " : " ")}
        </InputLabel>
        <Select
          size="medium"
          style={{ width: "100%" }}
          labelId="demo-simple-select-label"
          id={node.name}
          value={node.value || ""}
          label={t(node.title)}
          onChange={handleChange}
          disabled={node.disabled}
          error={node.error}
        >
          {node?.childrens &&
            node?.childrens.map((n, i) => {
              return (
                <MenuItem
                  onClick={() => setChange(n[node.displayProps?.value] || n)}
                  value={n[node.displayProps?.value] || n}
                  key={n.uuid}
                  disabled={n.disabled || false}
                >
                  {n[node.displayProps.label] || n}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
