import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
  index: number;
}

const initialState: CallState = {
  index: 0,
};

const tableButtonIndex = createSlice({
  name: "button1Index", // Use a different name for the slice
  initialState,
  reducers: {
    activeIndex: (state, action: PayloadAction<number>) => {
      state.index = action.payload;
    },
  },
});

export const { activeIndex } = tableButtonIndex.actions;
export default tableButtonIndex.reducer;
