import React, { useState } from "react";
import ComponentMap from "../atom/componentmap";
import ThemedButton from "../atom/ThemedButton/themedButton";
import FadeIn from "react-fade-in/lib/FadeIn";

function Myxtone() {
  const [xtoneData, setXToneData] = useState<any>([
    {
      title: "Incoming Number",
      name: "number",
      type: "textbox",
      value: "",
    },
    {
      title: "User ID (extension)",
      name: "userId",
      required: true,
      type: "textbox",
      value: "",
    },
    {
      title: "PIN",
      name: "pin",
      type: "password",
      value: "",
      layout: 4,
    },
    {
      title: "Remember my user ID",
      name: "IDcheckbox",
      type: "checkbox",
      value: "",
    },
  ]);

  const onHandleLogin = (result: any) => {
    console.log("resultresultresultresult", result);
  };
  return (
    <div
      data-testid="myxtone"
      className=" flex justify-center items-center  h-[calc(100vh-110px)]"
    >
      <FadeIn>
        <div className="h-[420px] w-[520px] bg-[#FFFFFF] rounded-lg">
          <div className="text-[16px] font-bold px-7 py-4">
            Vaspian X Tone Login
          </div>

          <div className="mt-4 relative px-5">
            <ComponentMap data={xtoneData} setData={setXToneData} />
            <div className="sm:w-[48%] md:w-[185px] w-[98%] mx-auto  sm:absolute bottom-2 sm:-right-[8px] sm:top-[152px] h-[56px] flex justify-start px-5 items-center">
              <a href="" className="hover:text-primary">
                Forgot Password?
              </a>
            </div>
          </div>

          <div className=" w-full h-full flex items-baseline justify-end mt-4 -ml-6 ">
            <ThemedButton
              children={"Login"}
              icon="custom"
              valiData={xtoneData}
              change={setXToneData}
              theme="primary"
              click={onHandleLogin}
            />
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

export default Myxtone;
