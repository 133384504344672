import React from "react";
import { Route, Routes } from "react-router-dom";
import PurchaseDidModal from "./purchaseDidModal";
import Dids from "./did_Management";
import Did_Lifecycle from "./did_Lifecycle";

function DidRouter() {
  console.log("checkkkk");
  return (
    <>
      <Routes>
        <Route
          path="/purchase"
          element={
            <React.Suspense fallback={<></>}>
              <PurchaseDidModal />
            </React.Suspense>
          }
        />
        <Route
          path="/:id/lifecycle"
          element={
            <React.Suspense fallback={<></>}>
              <Did_Lifecycle />
            </React.Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default DidRouter;
