// src/features/numberManagerSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NumberManagerRepoState {
  updateList: boolean;
  telcoData: any[];
  telcoId: string;
  clientData: any[];
}

const initialState: NumberManagerRepoState = {
  updateList: false,
  telcoData: [],
  telcoId: "",
  clientData: [],
};

const numberManagerSlice = createSlice({
  name: "numberManager",
  initialState,
  reducers: {
    setUpdateList: (state, action: PayloadAction<boolean>) => {
      state.updateList = action.payload;
    },
    setTelcoData: (state, action: PayloadAction<any[]>) => {
      state.telcoData = action.payload;
    },
    setTelcoId: (state, action: PayloadAction<string>) => {
      state.telcoId = action.payload;
    },
    setClientData: (state, action: PayloadAction<any[]>) => {
      state.clientData = action.payload;
    },
  },
});

export const { setUpdateList, setTelcoData, setTelcoId, setClientData } =
  numberManagerSlice.actions;
export default numberManagerSlice.reducer;
