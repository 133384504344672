// src/features/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

interface AuthRepoState {
  loggedUser: {};
  accessToken: string;
  token:string;
  loggedUserRoles: any;
  accountActiveStep: number;
}

const initialState: AuthRepoState = {
  loggedUser: {},
  accessToken:'',
  token:'',
  loggedUserRoles: [],
  accountActiveStep: 1,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{}>) => {
      state.loggedUser = action.payload;
    },
    setGAccessToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload ? action.payload : '';
      state.accessToken = action.payload ? jwtDecode(action.payload) : '';
    },
    setUserRoles: (state, action: PayloadAction<any>) => {
      state.loggedUserRoles = action.payload;
    },
    setAccountActiveState: (state, action: PayloadAction<number>) => {
      state.accountActiveStep = action.payload;
    },
  },
});

export const { setUser, setUserRoles, setAccountActiveState, setGAccessToken } = authSlice.actions;
export default authSlice.reducer;
