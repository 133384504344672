import React, { Children, useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import {
  CrossIcon,
  EditPencilIcon,
  EditPencilIcon2,
  TableSortIconDownArrow,
} from "../../utils/icons/defaultIcons";
import TableTopBar from "../modal/tableTopBar";
import Table from "../../atom/table";
import Loader from "../loader";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PaginationButtons from "../../atom/pagination";
import { Backdrop, CircularProgress } from "@mui/material";
import Notification from "../../atom/Notification";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import { setBreadcrumbs } from "../../toolkit/reducers/breadCrumbsSlice";
import ComponentMap from "../../atom/componentmap";
import { view_Individual_Plan } from "../../services/api";
import { pickersSlideTransitionClasses } from "@mui/x-date-pickers";

function View_Dcid_Plan() {
  const [manageState, setManageState] = useState<string>("");
  // const [tableData, setTableData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(25);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [planData, setPlanData] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id }: any = useParams();
  console.log("uuiddd", id);
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "state",
      title: "State",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: true },
    },
    {
      key: "rate_center",
      title: "Rate Center",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "npa",
      title: "NPA",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
  ]);

  const [tableData, setTableData] = useState<any>([]);

  const [searchtext, setSearchText] = useState<any>("");
  const [sort, setSort] = useState<any>({
    key: "state",
    order: "ASC",
  });

  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);

  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_direction: orderBy });
  };

  const path = useLocation();

  const onHandleFetch: any = (options: {
    limit?: number;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    setLoader(true);
    view_Individual_Plan(
      {
        limit: options?.limit || perPage,
        pageId: options?.pageNumber || pageNumber,
        sort_column: options?.sort_column || sort.key,
        sort_direction: options?.sort_direction || sort.order,
        searchtext:
          options?.searchtext === undefined
            ? searchtext
            : options?.searchtext === ""
            ? ""
            : options?.searchtext,
      },
      id
    ).then((result: any) => {
      console.log("result", result.data.items);
      const plans = result?.data?.items;
      if (!result.success || plans?.length === 0) {
        setLoader(false);
        setResultNotFound(true);
      } else {
        setResultNotFound(false);
      }
      setTableData(plans);
      setPlanData(result?.data);
      setLoader(false);
      setPageCount(result?.data.total);
    });
  };

  const handleChange = (pNUm: number) => {
    view_Plan(pNUm);
    setPageNumber(pNUm);
  };

  const view_Plan = (pgNum: number) => {
    setPageNumber(pgNum);
    onHandleFetch({ pageNumber: pgNum });
  };

  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, pageNumber: 1 });
  };

  // this function handles every button clicks for navigation :)
  const onhandleStateChange = (state: any, data: any) => {
    console.log("statestate", state);
    navigate(`${state}`, { replace: false });
  };

  const actionHandler = (action: string, data: any, index: number) => {
    const actionsMap: any = {
      edit: onhandleStateChange,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data);
    } else {
      console.log("actionHandler Error");
    }
  };

  // Breadcrubs

  const activeBreadCrumbs = useSelector(
    (state: any) => state.BreadCrumbsList.brudCrubName
  );
  var res = [...activeBreadCrumbs];

  const plan_Name = useSelector(
    (state: any) => state.BreadCrumbsList.dcid_PlanName
  );

  useEffect(() => {
    view_Plan(1);
    console.log("resssss", res);

    res[1] = {
      display: `${plan_Name}`,
      path: "dcid/" + id + "/viewplan",
    };
    res[2] = {
      display: "view DCID Plan",
      path: "dcid/" + id + "/viewplan",
    };
    dispatch(setBreadcrumbs(res));
  }, []);

  //

  return (
    <div>
      <div className="h-full py-3 flex relative  rounded-md">
        <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop>
        <div
          className={`h-[calc(100vh-125px)]   overflow-x-auto overflow-y-hidden  rounded-md bg-[#fff]`}
        >
          <FadeIn>
            <div className="pt-3">
              <TableTopBar
                title={plan_Name}
                button={false}
                manageState={manageState}
                setManageState={setManageState}
                onhandleStateChange={onhandleStateChange}
                permission={"provision.users.post"}
                // buttonText={"Purchase DIDs"}
                search={false}
                searchtext={searchtext}
                setSearchText={setSearchText}
                // handleSearch={handleSearch}
                handleKeyPress={handleKeyPress}
                // handleCSVDownload={true}
                // CSV={true}
                setHandleKeyPress={setHandleKeyPress}
                actionHandler={actionHandler}
              />
            </div>

            <Table
              tableTitles={tableTitles}
              tableData={tableData}
              setTableData={setTableData}
              actionHandler={actionHandler}
              setTableTitles={setTableTitles}
              button={true}
              buttonIndex={4}
              buttonText1={"manage"}
              width={"w-[calc(100vw-122px)] overflow-auto"}
              scrollHeight={"h-[calc(100vh-280px)] overflow-y-auto"}
              manageState={manageState}
              setManageState={setManageState}
              onHandleSort={onHandleSort}
              onhandleStateChange={onhandleStateChange}
              permission={"provision.users.put"}
              actions={true}
              resultNotFound={resultNotFound}
            />
            {!resultNotFound && tableData.length > 0 && (
              <div className="mt-6">
                <PaginationButtons
                  pageNumber={pageNumber}
                  handleChange={handleChange}
                  pageCount={pageCount}
                  perPage={perPage}
                  handlePerPage={handlePerPage}
                />
              </div>
            )}
          </FadeIn>
        </div>
      </div>
    </div>
  );
}

export default View_Dcid_Plan;
