import React, { useEffect, useState } from "react";
import Modal from "../../../atom/modal";
import SearchBar from "../../../atom/search";
import { UserIcon } from "../../../utils/icons/defaultIcons";
import { Checkbox } from "@mui/material";
import FadeIn from "react-fade-in";
import ThemedButton from "../../../atom/ThemedButton/themedButton";

function CreateGroup(props: any) {
  const { setCreateGroup } = props;

  const [userData, setUserData] = useState<any>([
    {
      extension: "1181",
      name: "Jose Illikkal",
    },
    {
      extension: "1182",
      name: "Lenise Danvoche",
    },
    {
      extension: "1183",
      name: "Jose Illikkal",
    },
    {
      extension: "1184",
      name: "Lenise Danvoche",
    },
    {
      extension: "1185",
      name: "Jose Illikkal",
    },
    {
      extension: "1186",
      name: "Lenise Danvoche",
    },
    {
      extension: "1187",
      name: "Jose Illikkal",
    },
    {
      extension: "1188",
      name: "Lenise Danvoche",
    },
    {
      extension: "1189",
      name: "Lenise Danvoche",
    },
    {
      extension: "1190",
      name: "Jose Illikkal",
    },
    {
      extension: "1191",
      name: "Lenise Danvoche",
    },
  ]);

  const getSmsUserData = () => {};

  const handleChecked = (data: any) => {
    const CopyUserData = [...userData];
    CopyUserData.forEach((node: any) => {
      if (node.extension === data.extension) {
        node.checked = !data?.checked || false;
      }
    });
    setUserData(CopyUserData);
  };
  useEffect(() => {
    getSmsUserData();
  }, []);

  return (
    <div className="">
      <Modal title={"Add User"} closeEvent={() => setCreateGroup(false)}>
        <div className="p-2">
          <SearchBar placeholder={"Search by name, extension"} />
          <div className="mt-4 md:w-[650px]  h-[430px] overflow-y-auto">
            <FadeIn>
              {userData.map((node: any, index: number) => {
                return (
                  <div
                    key={node?.extnesion}
                    onClick={() => handleChecked(node)}
                    className="flex justify-between border-b border-b-[#d1d1d1] pt-4 pb-5 px-2 hover:rounded-md hover:bg-[#FEF4E9] hover:cursor-pointer "
                  >
                    <div className="flex gap-1">
                      <div className="">
                        <UserIcon />
                      </div>
                      <p>{node?.extension}</p>
                    </div>
                    <p>{node?.name}</p>

                    <div>
                      <input
                        type="checkbox"
                        id={`checkbox_${index}`}
                        className={`w-4 h-4  rounded-lg cursor-pointer accent-[#F78F1E]`}
                        checked={node?.checked ? node.checked : false}
                        // onChange={() => handleChecked(node)}
                      />
                    </div>
                  </div>
                );
              })}
            </FadeIn>
          </div>
          <div className="text-right w-full  mt-6">
            <ThemedButton
              children={"Cancel"}
              icon="custom"
              theme="secondary"
              click={() => setCreateGroup(false)}
            />
            <ThemedButton
              children={"Add"}
              icon="custom"
              theme="primary"
              click={""}
              //   loading={buttonLoader}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateGroup;
