// counterSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  value: string;
}

const initialState: MenuState = {
  value: "",
};

const activeMenuSlice = createSlice({
  name: "menuItem",
  initialState,
  reducers: {
    setActiveMenu: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
      console.log(state, "state");
    },
  },
});

export const { setActiveMenu } = activeMenuSlice.actions;
export default activeMenuSlice.reducer;
