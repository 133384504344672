import React, { useState } from "react";
import FadeIn from "react-fade-in";
import TableTopBar from "./modal/tableTopBar";
import { TableSortIconDownArrow } from "../utils/icons/defaultIcons";
import Table from "../atom/table";

function TimeZoneRestriction() {
  const [hideDropdownValues, setHideDropdownValues] = useState<any>([
    { name: "Extension", checked: false },
    { name: "First Name", checked: false },
    { name: "Last Name", checked: false },
    { name: "Allow Time Zone Restriction", checked: false },
  ]);

  const [tableTitles, setTableTitles] = useState<any>([
    { key: "data1", title: "Extension", icon: <TableSortIconDownArrow /> },
    { key: "data2", title: "First Name", icon: <TableSortIconDownArrow /> },
    { key: "data3", title: "Last Name", icon: <TableSortIconDownArrow /> },
    {
      key: "data4",
      title: "Allow Time Zone Restriction",
      type: "toggle",
      toggleText: "Time Zone Restricted",
      toggleButton: true,
    },
  ]);

  const [tableData, setTableData] = useState<any>([
    { data1: "213", data2: "Lui", data3: "Remo", data4: true },
    { data1: "112", data2: "Chris", data3: "Remo", data4: true },
    { data1: "112", data2: "Remo", data3: "Remo", data4: false },
    { data1: "213", data2: "Ken", data3: "Ken", data4: true },
    { data1: "112", data2: "Raj", data3: "Ken", data4: true },
    { data1: "213", data2: "Nil", data3: "Ken", data4: true },
    { data1: "112", data2: "James", data3: "Chris", data4: true },
    { data1: "112", data2: "John", data3: "Chris", data4: true },
    { data1: "213", data2: "Aquiris", data3: "Ken", data4: true },
    { data1: "213", data2: "Belt", data3: "Chris", data4: true },
    { data1: "112", data2: "Kim", data3: "Ken", data4: true },
  ]);

  return (
    <div
      data-testid="timeZoneRestriction"
      className=" h-full py-3 flex relative gap-2.5"
    >
      <div className=" h-[calc(100vh-125px)]  w-[calc(100vw-126px)] rounded-md bg-[#FFFFFF]">
        <FadeIn>
          <div className="pt-3">
            <TableTopBar
              hideDropdownValues={hideDropdownValues}
              setHideDropdownValues={setHideDropdownValues}
              title={"Time Zone Restriction"}
            />
          </div>
          <Table
            tableTitles={tableTitles}
            tableData={tableData}
            setTableData={setTableData}
            setTableTitles={setTableTitles}
          />
        </FadeIn>
      </div>
    </div>
  );
}

export default TimeZoneRestriction;
