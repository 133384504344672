import { useEffect, useState } from "react";
import Modal from "../../../atom/modal";
import ComponentMap from "../../../atom/componentmap";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import Notification from "../../../atom/Notification";
import {
  actualDataToMapData,
  addChildrensToMapData,
  appendChildrensToMapData,
} from "../../../services/utilities";
import { editSmsDids, getSmsDids } from "../../../services/api";

function EditSmsUsers(props: any) {
  const { setEditUserModal, editUserData, setPageLoader } = props;
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [didList, setDidList] = useState<any>([]);
  const [userData, setUserData] = useState<any>([
    {
      title: "Extension",
      name: "extension",
      value: "",
      type: "number",
      minLength: 3,
      required: false,
      disabled: true,
      errormessage: "Please enter Extension.",
    },
    {
      title: "Name",
      name: "first_name",
      value: "",
      required: false,
      disabled: true,
      errormessage: "Please enter Name.",
    },
    {
      title: "SMS DID",
      name: "did",
      type: "dropdown",
      value: "",
      displayProps: { label: "did", value: "uuid" },
      childrens: [],
      required: true,
      errormessage: "Please select any SMS DID.",
    },
  ]);
  const handleSaveChanges = (result: any) => {
    setButtonLoader(true);
    let selected_did = didList?.filter(
      (node: any) => result?.did === node.uuid
    );

    const payload: any = {
      sms_did: selected_did[0]?.did,
      sms_did_id: selected_did[0]?.uuid,
    };
    editSmsDids(editUserData?.uuid, payload).then((result: any) => {
      if (!result?.success) {
        Notification("Failed", result?.message, "danger");
        setButtonLoader(false);
      } else {
        Notification("Success", "SMS DID updated successfully", "success");
        setEditUserModal(false);
        setButtonLoader(false);
        setPageLoader(true);
      }
    });
  };
  const fetchSmsDidList = () => {
    getSmsDids().then((result: any) => {
      if (!result?.success) {
        Notification("Oops!", "SMS DIDs not found", "danger");
      } else {
        setDidList(result?.data?.items);
      }
      setUserData(
        addChildrensToMapData([...userData], result?.data?.items, "did")
      );
    });
  };
  useEffect(() => {
    let edituserDataCopy = { ...editUserData };
    edituserDataCopy.did = editUserData.sms_did_id;
    edituserDataCopy.first_name =
      editUserData.first_name + " " + editUserData.last_name;
    console.log(
      "editUserData",
      editUserData,
      "edituserDataCopy",
      edituserDataCopy
    );
    setUserData(actualDataToMapData([...userData], edituserDataCopy));
    fetchSmsDidList();
  }, []);
  return (
    <Modal title={"Edit SMS Users"} closeEvent={() => setEditUserModal(false)}>
      <div className="w-[600px] smallPicker">
        <ComponentMap data={userData} setData={setUserData} />
        <div className="text-right w-full mt-5">
          <ThemedButton
            children={"Cancel"}
            icon="custom"
            theme="secondary"
            click={() => setEditUserModal(false)}
          />
          <ThemedButton
            children={"Save Changes"}
            icon="custom"
            theme="primary"
            click={handleSaveChanges}
            valiData={userData}
            change={setUserData}
            loading={buttonLoader}
          />
        </div>
      </div>
    </Modal>
  );
}
export default EditSmsUsers;
