import React from 'react'

const TextLabel = (props: any) => {
    const { title } = props;
    return (
        <div className=' font-semibold text-base opacity-60 text-[#242424]'>
            {title}
        </div>
    )
}

export default TextLabel