import { useEffect } from "react"
import Loader from "../containers/loader"
import { LeftMenuItems } from "../config/leftMenuItems"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../services/utilities";

const InitialRouter = () => {
    const navigate = useNavigate();
    const permissionsList = useSelector(
        (state: any) => state.Permissions.permissions
    );
    const handleNavigation = (node: any, item: any = {}) => {
        console.log("nodenodenode",node)
        if (item?.path) {
            navigate(`/app/${node.path}/${item.path}`, { replace: true });
        } else if (node?.childrens) {
            navigate(`/app/${node.path}/${node.childrens[0].path}`, { replace: true });
        } else {
            navigate(`/app/${node.path}`, { replace: true });
        }
    }
    useEffect(() => {
        var data = LeftMenuItems["control"].map( x => ({...x}) )
        var temp: any = []
        data.map((node: any) => {
            if (node?.childrens) {
                // debugger
                var child: any = []
                node?.childrens.map((item: any) => {
                    if (hasPermission(node.item, item.permission, permissionsList)) {
                        child.push(item)
                    }
                })
                node.childrens = child
                if (child.length) {
                    temp.push(node)
                }
            } else if (hasPermission(node.item, node.permission, permissionsList)) {
                temp.push(node)
            }
        })
        if(temp[0]){
            handleNavigation(temp[0])
        }else{
            navigate(`/app/404`, { replace: true });
        }
    }, [permissionsList])
    return <div className="w-full h-full flex flex-col justify-center">
        <Loader />
    </div>
}
export default InitialRouter