

export const production = {
    faro: {
        url: "https://one.vaspian.com/collect",
        apiKey: "VO7sZzuQ",
        app: {
            name: "vaspprod",
            version: "1.0.0",
        },
    }
}