import FadeIn from "react-fade-in/lib/FadeIn";
import SearchBar from "../../../atom/search";
import { useEffect, useRef, useState } from "react";
import AgentInfoCard from "../queueMonitor/agentInfoCard";
import TableTopBar from "../../modal/tableTopBar";
import { useSelector } from "react-redux";
import { getToken } from "../../../services/api";
import { getENVData } from "../../../config/environment";
let intervalId: any;
function UserPanel() {
  const tabs = [
    { name: "State" },
    { name: "Extension" },
    { name: "First Name" },
    { name: "Last Name" },
  ];

  const isComponentMounted = useRef(false); // To track if the component is mounted
  const accessToken = useSelector((state: any) => state.auth.token);
  const [sortBy, setSortBy] = useState<string>("State");
  const [searchtext, setSearchText] = useState<any>("");
  const [eventsData, setEventsData] = useState<any>([]);
  const socketRef = useRef<WebSocket | null>(null);
  const messageBuffer = useRef<string[]>([]);
  const connectWebSocket = () => {
    if (!isComponentMounted.current) return;
    const wssUrl = getENVData().baseURL.replace("https", "wss");
    // Only create the WebSocket if it hasn't been created yet
    const url = `${wssUrl}events/liveboard/tenants/${getToken()}/ws/users?token=${accessToken}`;
    console.log("wssUrl", url);
    if (!socketRef.current) {
      socketRef.current = new WebSocket(url);
      socketRef.current.addEventListener("open", (event) => {
        console.log("Connected to WebSocket", event);
      });

      socketRef.current.addEventListener("message", (event) => {
        // console.log("Message received from server:", event.data);
        const data = JSON.parse(event.data);
        if (data?.event === "user.create") {
          messageBuffer.current.push(data);
          // setEventsData((prevEvents: any) => [...prevEvents, data]);
          // onHandleSort("State");
        }
        if (data?.event === "user.update") {
          // console.log("user.update", data);
          setEventsData((prevEvents: any) =>
            prevEvents.map((event: any) =>
              event.uuid === data.uuid ? { ...event, ...data } : event
            )
          );
          // onHandleSort(sort);
        }
        if (data?.event === "user.delete") {
          setEventsData((prevEvents: any) =>
            prevEvents.filter((event: any) => event.uuid !== data.uuid)
          );
        }
      });

      socketRef.current.addEventListener("close", (e: Event) => {
        if (isComponentMounted.current) {
          console.log("WebSocket connection closed", socketRef.current, e);
          console.log("WebSocket connection closed Attempting to reconnect...");
          socketRef.current = null;
          reconnectWebSocket();
        } else {
          console.log(
            "WebSocket closed and no reconnect as component is unmounted."
          );
        }
      });
      socketRef.current.addEventListener("error", (error) => {
        console.error("WebSocket encountered an error:", error);
        if (socketRef.current) {
          socketRef.current.close(1000, "Normal closure"); // Close the connection on error
        }
      });
    }
    intervalId = setInterval(() => {
      if (messageBuffer.current.length > 0) {
        setEventsData((prevEvents: any) => [
          ...prevEvents,
          ...messageBuffer.current,
        ]);
        messageBuffer.current = []; // Clear the buffer after processing
      }
    }, 100);
  };
  const reconnectWebSocket = () => {
    if (!isComponentMounted.current) return; // Stop reconnecting if the component is unmounted
    setTimeout(() => {
      console.log("Reconnecting WebSocket...");
      connectWebSocket(); // Retry connection
    }, 3000); // Retry after 3 seconds
  };
  useEffect(() => {
    setEventsData(() => []);
    isComponentMounted.current = true; // Component is mounted
    if (socketRef.current) {
      socketRef.current.close(1000, "Normal closure");
      console.log("WebSocket closed during cleanup");
    }
    setTimeout(connectWebSocket, 100);
    return () => {
      isComponentMounted.current = false; // Component is mounted
      if (socketRef.current) {
        socketRef.current.close(1000, "Normal closure");
        console.log("WebSocket closed during cleanup");
        // setTimeout(connectWebSocket, 3000);
      }
      console.log("socketRef", socketRef);
      clearInterval(intervalId); // Cleanup the interval
    };
  }, []);

  return (
    <div data-testid="userPanel" className="h-full py-3 flex relative gap-2.5">
      <div className=" h-[calc(100vh-125px)]  w-[calc(100vw-120px)] rounded-md bg-[#FFFFFF]">
        <FadeIn>
          {/* Top */}
          <div className="py-5 px-4">
            <TableTopBar
              title={"User Panel"}
              search={true}
              searchtext={searchtext}
              // handleSearch={handleSearch}
              setSearchText={setSearchText}
              // filterTable={filterTable}
            />
          </div>
          {/*  */}
          <div className="flex gap-4 px-8">
            <p className="mt-1.5">Sort by</p>
            {tabs.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={`rounded-lg   h-min-[40px]  break-words ${
                    sortBy === item.name
                      ? "text-[#AB5D0A] font-bold bg-[#FEF4E9]"
                      : "bg-[#EBEBEB80]"
                  } text-center bg-[#EBEBEB80] h-[38px] px-3 cursor-pointer hover:bg-[#FEF4E9] hover:text-[#AB5D0A]   flex justify-center items-center`}
                  onClick={() => setSortBy(item.name)}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          {/* main body */}

          <div className="w-full mt-4  px-8 grid xl:grid-cols-4  gap-x-4 md:grid-cols-3 sm:grid-cols-1 overflow-y-auto max-h-[calc(100vh-270px)]">
            {/* <div className="w-full mt-4 px-8 grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-5  overflow-visible  max-h-[calc(100vh-270px)]"> */}
            {eventsData
              ?.sort((a: any, b: any) => {
                if (sortBy === "State") {
                  const getPriority = (event: any) => {
                    if (!event.is_logged_in) return 3; // Thirdeeee
                    if (event.ph_state === "idle") return 2; // Secondee
                    if (event.ph_state === "busy") return 1; //firsteee
                    return 4;
                  };

                  return getPriority(a) - getPriority(b);
                }

                if (sortBy === "Extension") {
                  return (a.extension || "").localeCompare(b.extension || "");
                }

                if (sortBy === "First Name") {
                  return (a.first_name || "").localeCompare(b.first_name || "");
                }

                if (sortBy === "Last Name") {
                  return (a.last_name || "").localeCompare(b.last_name || "");
                }
                return 0;
              })
              .map((node: any, index: number) => {
                return (
                  <div key={node.uuid}>
                    <AgentInfoCard page={"main"} data={node} />
                  </div>
                );
              })}
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default UserPanel;
