import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  data: any[];
}

const initialState: MenuState = {
  data: [],
};

const userDataSlice = createSlice({
  name: "currentUserData",
  initialState,
  reducers: {
    setCurrentUserData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCurrentUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
