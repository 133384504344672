import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect } from "react";

interface CrumbsState {
  brudCrubName: any[];
  tenantName: String;
  dcid_PlanName: string;
}

const initialState: CrumbsState = {
  brudCrubName: [],
  tenantName: "",
  dcid_PlanName: "",
};

const breadCrumbs = createSlice({
  name: "BreadCrumbsList", // Use a different name for the slice
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<any[]>) => {
      state.brudCrubName = action.payload;
      console.log("brudCrubName", state.brudCrubName);
    },
    setTenantName: (state, action: PayloadAction<String>) => {
      state.tenantName = action.payload;
    },
    setPlanName: (state, action: PayloadAction<any>) => {
      state.dcid_PlanName = action.payload;
    },
  },
});

export const { setBreadcrumbs, setTenantName, setPlanName } =
  breadCrumbs.actions;
export default breadCrumbs.reducer;
