import React, { createContext, useEffect } from "react";
import { baseTheme } from "./baseTheme";
import { darkTheme } from "./darkTheme";

interface ThemeContextType {
  theme: string;
  toggleTheme: (theme: string, callback: VoidFunction) => void;
  createTheme: (theme: ThemeType) => any;
  componentTheme: (primary: string, callback: VoidFunction) => void;
  getCurrentThemeJSON: (theme: string, callback: Function) => void;
}
interface ThemeType {
  primary: string;
  primaryAlpha20: string;
  primaryAlpha40: string;
  primaryAlpha60: string;
  primaryAlpha80: string;
  primaryText: string;
  secondary: string;
  secondaryText: string;
  ternary: string;
  ternaryText: string;
  hover: string;
  tableHeader: string;
}

// Context API used for Theme related information and methods.
export let ThemeContext = createContext<ThemeContextType>(null!);

// Context Provider to wrap the whole app within and make auth information available.
export function ThemeProvider({ children }: { children: React.ReactNode }) {
  let [theme, setTheme] = React.useState<string>("light");

  useEffect(() => {
    toggleTheme(theme, () => {});
  }, []);

  let getCurrentThemeJSON = (themes: string, callback: Function) => {
    const themesJson: ThemeType = themes === "dark" ? darkTheme : baseTheme;
    callback(themesJson);
    // return themesJson;
  };
  let toggleTheme = (themes: string, callback: VoidFunction) => {
    setTheme(themes);
    const themesJson: ThemeType = themes === "dark" ? darkTheme : baseTheme;
    const root = document.documentElement;
    const createdTheme = createTheme(themesJson, "skipPrimary");
    Object.keys(createdTheme).forEach((cssVar: string) => {
      root.style.setProperty(cssVar, createdTheme[cssVar]);
    });
  };
  let componentTheme = (primary: string, callback: VoidFunction) => {
    const themesJson: ThemeType = theme === "dark" ? darkTheme : baseTheme;
    const root = document.documentElement;
    const createdTheme = createTheme(themesJson, primary);

    Object.keys(createdTheme).forEach((cssVar: string) => {
      root.style.setProperty(cssVar, createdTheme[cssVar]);
    });
  };
  // function convertRGBAToHex(rgba: string) {
  //   let code: any = rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
  //     .split(',') // splits them at ","
  //   let alpha = parseFloat(code.filter((string: any, index: number) => index === 3)[0])
  //   code = code.filter((string: any, index: number) => index !== 3).map((string: any) => {
  //     return Math.floor((parseInt(string) / 0.2))
  //   })
  //     .map((string: any) => string.length === 1 ? "0" + string : string)
  //   // .map((number:any) => number.toString(16))
  //   // .join("");
  //   console.log("codecode", code, alpha, rgba)

  //   // return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
  //   // .split(',') // splits them at ","
  //   // .filter((string, index) => !forceRemoveAlpha || index !== 3)
  //   // .map(string => parseFloat(string)) // Converts them to numbers
  //   // .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
  //   // .map(number => number.toString(16)) // Converts numbers to hex
  //   // .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
  //   // .join("") // Puts the array to togehter to a string
  //   return "#" + code
  // }
  const convertHexToRGBA = (hexCode: string, opacity = 1) => {
    let hex = hexCode.replace("#", "");

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
  };
  const createTheme = (theme: ThemeType, primary: string = ""): any => {
    if (primary === "skipPrimary") {
      return {
        "--theme-primary": theme.primary,
        "--theme-primary-alpha-5": convertHexToRGBA(theme.primary, 0.05),
        "--theme-primary-alpha-10": convertHexToRGBA(theme.primary, 0.1),
        "--theme-primary-alpha-20": convertHexToRGBA(theme.primary, 0.2),
        "--theme-primary-alpha-30": convertHexToRGBA(theme.primary, 0.3),
        "--theme-primary-alpha-40": convertHexToRGBA(theme.primary, 0.4),
        "--theme-primary-alpha-50": convertHexToRGBA(theme.primary, 0.5),
        "--theme-primary-alpha-60": convertHexToRGBA(theme.primary, 0.6),
        "--theme-primary-alpha-80": convertHexToRGBA(theme.primary, 0.8),
        "--theme-primary-text": theme.primaryText,
        "--theme-secondary": theme.secondary,
        "--theme-secondary-text": theme.secondaryText,
        "--theme-ternary": theme.ternary,
        "--theme-ternary-text": theme.ternaryText,
        "--theme-hover": theme.hover,
        "--theme-table-header": convertHexToRGBA(theme.primary, 0.6),
      };
    } else {
      primary = primary ? primary : theme.primary;
      return {
        "--theme-primary": primary,
        "--theme-primary-alpha-5": convertHexToRGBA(primary, 0.05),
        "--theme-primary-alpha-10": convertHexToRGBA(primary, 0.1),
        "--theme-primary-alpha-20": convertHexToRGBA(primary, 0.2),
        "--theme-primary-alpha-30": convertHexToRGBA(primary, 0.3),
        "--theme-primary-alpha-40": convertHexToRGBA(primary, 0.4),
        "--theme-primary-alpha-50": convertHexToRGBA(primary, 0.5),
        "--theme-primary-alpha-60": convertHexToRGBA(primary, 0.6),
        "--theme-primary-alpha-80": convertHexToRGBA(primary, 0.8),
        "--theme-primary-text": theme.primaryText,
        "--theme-secondary": theme.secondary,
        "--theme-secondary-text": theme.secondaryText,
        "--theme-ternary": theme.ternary,
        "--theme-ternary-text": theme.ternaryText,
        "--theme-hover": theme.hover,
        "--theme-table-header": convertHexToRGBA(primary, 0.6),
      };
    }
  };

  let value = {
    theme,
    toggleTheme,
    createTheme,
    componentTheme,
    getCurrentThemeJSON,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

// Custom hook to access auth related data and methods.
// Most important hook to be used throughout
export function useTheme() {
  return React.useContext(ThemeContext);
}
