import { Backdrop } from "@mui/material";
import React, { useState } from "react";
import Lottiefy from "../../../atom/lottie/lottie";
import TableTopBar from "../../modal/tableTopBar";
import FadeIn from "react-fade-in";
import {
  DeleteIcon,
  EditPencilIcon,
  EditPencilIcon2,
  TableSortIconDownArrow,
} from "../../../utils/icons/defaultIcons";
import Table from "../../../atom/table";
import CreateGroup from "./createGroup";
// import AddSmsUserModal from "../../addSmsUserModal";
// import EditSmsUsers from "./editSmsUsers";
// import DeleteSmsUserModal from "./deleteSmsUserModal";

function SmsGroups() {
  const [createGroup, setCreateGroup] = useState<any>(false);
  const [editUserModal, setEditUserModal] = useState<any>(false);
  const [deleteUserModal, setDeleteUserModal] = useState<any>(false);
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "Group Name",
      title: "Group Name",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "grpsmsdid",
      title: "Group SMS DID",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "usersCount",
      title: "Number of Users",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "action_button",
      title: "",
      type: "actionIcon",
      class: "text-right -mb-2",
      icon: <EditPencilIcon />,
      action: {
        title: "edit",
        command: "edit",
        action: "click",
        tooltip: "Edit",
      },
    },
  ]);

  const [tableData, setTableData] = useState<any>([
    {
      usersCount: "2",
    },
  ]);

  const handleCreateGroup = () => {
    setCreateGroup(true);
  };

  const handleEdit = () => {
    setEditUserModal(true);
  };

  const handleDelete = () => {
    setDeleteUserModal(true);
  };

  const actionHandler = (action: string, data: any, index: number) => {
    console.log("action", action);
    const actionsMap: any = {
      "Create group": handleCreateGroup,
      edit: handleEdit,
      delete: handleDelete,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data);
    } else {
      console.log("actionHandler Error");
    }
  };

  return (
    <div className="h-full py-2.5  relative  rounded-md">
      {/* <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop> */}
      <div className="h-[calc(100vh-125px)] w-[calc(100vw-118px)] overflow-x-auto overflow-y-hidden  rounded-md bg-[#fff]">
        {createGroup && <CreateGroup setCreateGroup={setCreateGroup} />}
        {/* {editUserModal && <EditSmsUsers setEditUserModal={setEditUserModal} />}
        {deleteUserModal && (
          <DeleteSmsUserModal setDeleteUserModal={setDeleteUserModal} />
        )} */}

        <FadeIn className="pt-3">
          <TableTopBar
            title={"SMS Groups"}
            button={true}
            buttonText={"Create group"}
            permission={"provision.users.post "}
            actionHandler={actionHandler}
          />

          <Table
            tableTitles={tableTitles}
            tableData={tableData}
            actionHandler={actionHandler}
          />
        </FadeIn>
      </div>
    </div>
  );
}

export default SmsGroups;
