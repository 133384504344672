import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
  simulate: {};
}

const initialState: CallState = {
  simulate: {},
};

const Simulation = createSlice({
  name: "Simulation", // Use a different name for the slice
  initialState,
  reducers: {
    setSimuation: (state, action: PayloadAction<string>) => {
      state.simulate = action.payload;
    },
  },
});

export const { setSimuation } = Simulation.actions;
export default Simulation.reducer;
