
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';


const TimePick = (props) => {
    const { node, change, data, validation } = props;
    const setChange = (value) => {
        console.log(value, 'lamoraaa');

        let updateData = [...data];
        let filter = updateData.filter((n) => {
            return n.name === node.name;
        });
        filter[0].value = value;
        change([...updateData])
    }

    return (
        <div >
            <LocalizationProvider dateAdapter={AdapterDateFns} error={false}>
                <TimePicker
                    label={node.title + (node.required ? ' *' : '')}
                    value={node.value ? node.value : ''}
                    onChange={(newValue) => {
                        setChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} error={!validation && node.required && !node.value} />}
                />
            </LocalizationProvider>
        </div>
    )
}

export default TimePick;