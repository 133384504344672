import LoginConfig from "../containers/auth/login/login.config";


export const navigation = [
    {
        title: 'Login',
        path: "/auth/login",
        icon: 'lock',
        transalate: 'Login',
        permissions: [
            'public'
        ],
        config: LoginConfig,
    },
   
];
