import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
  activeMenu: number;
}

const initialState: CallState = {
  activeMenu: 0,
};

const liveBoards = createSlice({
  name: "ActiveBoard", // Use a different name for the slice
  initialState,
  reducers: {
    ActiveLiveBoard: (state, action: PayloadAction<number>) => {
      state.activeMenu = action.payload;
      console.log("state.activeMenu", state.activeMenu);
    },
  },
});

export const { ActiveLiveBoard } = liveBoards.actions;
export default liveBoards.reducer;
