import React, { Children, useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import {
  CrossIcon,
  EditPencilIcon,
  EditPencilIcon2,
  TableSortIconDownArrow,
} from "../../utils/icons/defaultIcons";
import TableTopBar from "../modal/tableTopBar";
import Table from "../../atom/table";
import Loader from "../loader";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PaginationButtons from "../../atom/pagination";
import { Backdrop, CircularProgress, ListItemText } from "@mui/material";
import Notification from "../../atom/Notification";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import { setBreadcrumbs } from "../../toolkit/reducers/breadCrumbsSlice";
import ComponentMap from "../../atom/componentmap";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import {
  add_Rate_Center,
  get_Rate_Centers,
  get_States,
} from "../../services/api";
import { set } from "date-fns";
import { addChildrensToMapData } from "../../services/utilities";

function Rate_Center() {
  const [manageState, setManageState] = useState<string>("");
  // const [tableData, setTableData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(25);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [displayTable, setDisplayTable] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "rate_center",
      title: "Rate Center",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "npa",
      title: "NPA",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "",
      type: "checkbox",
      selectAll: true,
      classname: "float-right ",
    },
  ]);

  const [tableData, setTableData] = useState<any>([]);

  const [searchtext, setSearchText] = useState<any>("");
  const [sort, setSort] = useState<any>({
    key: "rate_center",
    order: "ASC",
  });
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);
  const [stateList, setStateList] = useState<any>([
    {
      title: "Select State",
      name: "state",
      value: "",
      disabled: false,
      type: "multiselectdropdown",
      displayProps: {
        label: "state",
        value: "state",
      },
      childrens: [],
      required: false,
      errormessage: "Select a State",
    },
  ]);

  const path = useLocation();

  const onHandleFetch: any = (options: {
    limit?: number;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    setLoader(true);
    get_Rate_Centers(
      {
        limit: options?.limit || perPage,
        pageId: options?.pageNumber || pageNumber,
        sort_column: options?.sort_column || sort.key,
        sort_direction: options?.sort_direction || sort.order,
        searchtext:
          options?.searchtext === undefined
            ? searchtext
            : options?.searchtext === ""
            ? ""
            : options?.searchtext,
      },
      stateList[0]?.value?.state_code
    ).then((result: any) => {
      const plans = result?.data?.items;
      if (!result.success || plans?.length === 0) {
        setLoader(false);
        setResultNotFound(true);
      } else {
        setResultNotFound(false);
      }
      setTableData(plans);
      // setPlanData(result?.data);
      setLoader(false);
      setPageCount(result?.data.total);
    });
  };

  const selectAllCheckbox = (action: any, data: any) => {
    console.log("checboxdata", data);
    const clone = [...tableData];
    const ttile = [...tableTitles];
    if (data?.selectAll === true) {
      console.log("rchdddddd");
      const result: any = ttile.find((node: any) => {
        console.log("keyy.node", node.key, "data.key", data.key);
        return node.key === data.key;
      });
      console.log("result", result);
      result.checked = !result?.checked || false;
      clone.map((node: any) => {
        node.checked = result?.checked;
      });
    } else {
      console.log("rchdddddd222");

      const result = clone.find((node: any) => {
        return node.npa === data.npa && node.rate_center === data.rate_center;
      });
      console.log("checkedones", result);
      result.checked = !result?.checked || false;
    }
    const result = clone.find((node: any) => {
      return node?.checked ? node?.checked : false;
    });

    setTableTitles(ttile);
    setTableData(clone);
  };

  const handleStateSearch = () => {
    console.log("ghjk", stateList[0]);
    if (stateList[0].value) {
      setDisplayTable(true);
      setPageNumber(1);
      onHandleFetch({ pageNumber: 1 });
    } else {
      Notification("Required", "Select a State", "danger");
      setDisplayTable(false);
    }
  };

  const list_States = () => {
    get_States().then((result: any) => {
      const states = result?.data.items;
      console.log("statesstates", states);
      setStateList(addChildrensToMapData([...stateList], states, "state"));
    });
  };

  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, pageNumber: 1 });
  };

  const handleChange = (pgNum: number) => {
    onHandleFetch({ pageNumber: pgNum });
    setPageNumber(pgNum);
  };

  const onHandleCancel = () => {
    const clone = [...tableData];
    const title = [...tableTitles];
    const result = clone.map((node: any) => {
      return (node.checked = false);
    });
    title[2].checked = false;
    setTableData(clone);
    setTableTitles(title);
  };

  const onHandleAdd = () => {
    setButtonLoader(true);
    const tableDataCopy = [...tableData];
    const filteredData = tableDataCopy
      .filter((node: any) => {
        return node?.checked;
      })
      .map((item: any) => ({
        state_code: item.state,
        rate_center_abbr: item.rate_center_abbr,
        rate_center: item.rate_center,
        npa: item.npa,
      }));
    const payload = {
      npas: filteredData,
    };

    add_Rate_Center(payload).then((result: any) => {
      if (!result.success) {
        Notification(
          "Failed",
          `${result?.message ? result?.message : "Oops! Something went wrong"}`,
          "danger"
        );
        setButtonLoader(false);
      } else {
        setButtonLoader(false);
        Notification("Success", "Rate Center added successfully", "success");
        navigate(-1);
      }
    });
    console.log("payload", payload);
  };

  // this function handles every button clicks for navigation :)
  const onhandleStateChange = (state: any, data: any) => {
    console.log("statestate", state);
    if (state === "Rate Center") {
      state = "ratecenter";
    }
    navigate(`${state}`, { replace: false });
  };

  const actionHandler = (action: string, data: any, index: number) => {
    console.log("action", action, "data", data);
    const actionsMap: any = {
      selectall: selectAllCheckbox,
      change: selectAllCheckbox,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data);
    } else {
      console.log("actionHandler Error");
    }
  };
  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_direction: orderBy });
  };

  useEffect(() => {
    list_States();
  }, []);

  return (
    <div>
      <div className="h-full py-3 flex relative  rounded-md">
        <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop>
        <div
          className={`h-[calc(100vh-125px)] w-full  overflow-x-auto overflow-y-hidden  rounded-md bg-[#fff]`}
        >
          <FadeIn>
            <div className="pt-3">
              <TableTopBar
                title={"Rate Center"}
                button={false}
                manageState={manageState}
                setManageState={setManageState}
                onhandleStateChange={onhandleStateChange}
                permission={"provision.users.post"}
                search={false}
                searchtext={searchtext}
                setSearchText={setSearchText}
                // handleSearch={handleSearch}
                handleKeyPress={handleKeyPress}
                // handleCSVDownload={true}
                CSV={false}
                setHandleKeyPress={setHandleKeyPress}
                actionHandler={actionHandler}
              />
            </div>
            <div className="p-4">
              <div className="p-2 w-full bg-[#F7EFE7] h-[73px] flex items-center rounded-md">
                Here we will list all the rate centers and their NPAs that are
                not already in the system.
              </div>
            </div>
            <div className="p-2 py-4  smallPicker flex">
              <div className="w-[18%]">
                <ComponentMap data={stateList} setData={setStateList} />
              </div>
              <div className="mt-1">
                <ThemedButton
                  children={"Search"}
                  icon="custom"
                  theme="primary"
                  // valiData={callReport}
                  // change={setCallReport}
                  click={handleStateSearch}
                />
              </div>
            </div>
            {displayTable && (
              <>
                <div className="h-[calc(100vh-532px)]">
                  <Table
                    tableTitles={tableTitles}
                    tableData={tableData}
                    setTableData={setTableData}
                    actionHandler={actionHandler}
                    setTableTitles={setTableTitles}
                    button={true}
                    buttonIndex={4}
                    buttonText1={"manage"}
                    width={"w-[calc(100vw-122px)] overflow-auto"}
                    scrollHeight={"h-[calc(100vh-520px)] overflow-y-auto"}
                    manageState={manageState}
                    setManageState={setManageState}
                    onHandleSort={onHandleSort}
                    onhandleStateChange={onhandleStateChange}
                    permission={"provision.users.put"}
                    actions={true}
                    resultNotFound={resultNotFound}
                  />
                </div>

                <div className=" h-full py-4 px-3 flex  mt-2 float-right">
                  <ThemedButton
                    children={"Cancel"}
                    icon="custom"
                    theme="secondary"
                    // valiData={callReport}
                    // change={setCallReport}
                    click={onHandleCancel}
                  />
                  <ThemedButton
                    children={"Add"}
                    icon="custom"
                    theme="primary"
                    click={() => {
                      onHandleAdd();
                    }}
                    // valiData={callReport}
                    // change={setCallReport}
                    // click={onHandleUpdate}
                    loading={buttonLoader}
                  />
                </div>

                {!resultNotFound && tableData.length > 0 && (
                  <PaginationButtons
                    pageNumber={pageNumber}
                    handleChange={handleChange}
                    pageCount={pageCount}
                    perPage={perPage}
                    handlePerPage={handlePerPage}
                  />
                )}
              </>
            )}
          </FadeIn>
        </div>
      </div>
    </div>
  );
}

export default Rate_Center;
