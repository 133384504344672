import { Backdrop } from "@mui/material";
import React, { useEffect, useState } from "react";
import Lottiefy from "../../../atom/lottie/lottie";
import TableTopBar from "../../modal/tableTopBar";
import FadeIn from "react-fade-in";
import {
  DeleteIcon,
  EditPencilIcon,
  EditPencilIcon2,
  TableSortIconDownArrow,
} from "../../../utils/icons/defaultIcons";
import Table from "../../../atom/table";
import AddSmsUserModal from "./addSmsUserModal";
import EditSmsUsers from "./editSmsUsers";
import DeleteSmsUserModal from "./deleteSmsUserModal";
import { getSmsUsersList } from "../../../services/api";
import * as loaderLottie from "../../../utils/lottie/loaderLottie.json";
import { setUser } from "../../../toolkit/reducers/authSlice";
import PaginationButtons from "../../../atom/pagination";

function SmsUsers() {
  const [addUserModal, setAddUserModal] = useState<any>(false);
  const [editUserModal, setEditUserModal] = useState<any>(false);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const [pageLoader, setPageLoader] = useState<any>(false);
  const [pageCount, setPageCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(25);
  const [deleteUserModal, setDeleteUserModal] = useState<any>(false);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [loader, setLoader] = useState<any>(false);
  const [searchtext, setSearchText] = useState<any>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);
  const [sort, setSort] = useState<any>({
    key: "extension",
    order: "ASC",
  });
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "extension",
      title: "Extension",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: true },
    },
    {
      key: "first_name",
      title: "First Name",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "last_name",
      title: "Last Name",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "sms_did",
      title: "SMS DID",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "action_button",
      title: "",
      type: "actionIcon",
      class: "text-right -mb-2",
      icon: <EditPencilIcon />,
      action: {
        title: "edit",
        command: "edit",
        action: "click",
        tooltip: "Edit",
      },
    },
    {
      key: "action_button",
      title: "",
      type: "actionIcon",
      class: "text-right ",
      icon: <DeleteIcon />,
      action: {
        title: "delete",
        command: "delete",
        action: "click",
        tooltip: "Delete",
      },
    },
  ]);

  const [tableData, setTableData] = useState<any>([]);

  const onAddUser = () => {
    setAddUserModal(true);
  };

  const handleEdit = (action: string, data: any) => {
    setEditUserModal(true);
    setSelectedRowData(data);
  };

  const handleDelete = (action: string, data: any) => {
    setDeleteUserModal(true);
    setSelectedRowData(data);
  };

  const onHandleFetch: any = (options: {
    limit?: number;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    setLoader(true);
    getSmsUsersList({
      limit: options?.limit || perPage,
      pageId: options?.pageNumber || pageNumber,
      sort_column: options?.sort_column || sort.key,
      sort_direction: options?.sort_direction || sort.order,
      searchtext:
        options?.searchtext === undefined
          ? searchtext
          : options?.searchtext === ""
          ? ""
          : options?.searchtext,
    }).then((result: any) => {
      const smsUsers = result?.data?.data;
      if (!result?.success || smsUsers?.length === 0) {
        setLoader(false);
        setResultNotFound(true);
      } else {
        setLoader(false);
        setResultNotFound(false);
      }
      setTableData(smsUsers);
      setPageCount(result?.data?.total_count);
    });
  };

  const getUsersList = (pgNum: number) => {
    setPageLoader(false);
    setPageNumber(pgNum);
    onHandleFetch({ pageNumber: pgNum });
  };

  const handleChange = (pNUm: number) => {
    getUsersList(pNUm);
    setPageNumber(pNUm);
  };

  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, pageNumber: 1 });
  };

  const handleSearch = (data: string) => {
    setPageNumber(1);
    setSearchText(data);
    onHandleFetch({ searchtext: data, pageNumber: 1, limit: perPage });
  };

  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_direction: orderBy });
  };

  const actionHandler = (action: string, data: any, index: number) => {
    console.log("action", action);
    const actionsMap: any = {
      "Add SMS User": onAddUser,
      edit: handleEdit,
      delete: handleDelete,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data);
    } else {
      console.log("actionHandler Error");
    }
  };

  useEffect(() => {
    getUsersList(1);
  }, []);

  useEffect(() => {
    if (pageLoader) {
      getUsersList(1);
    }
  }, [pageLoader]);

  return (
    <div className="h-full py-2.5  relative  rounded-md">
      {addUserModal && (
        <AddSmsUserModal
          setAddUserModal={setAddUserModal}
          setPageLoader={setPageLoader}
          tableData={tableData}
        />
      )}
      {editUserModal && (
        <EditSmsUsers
          setEditUserModal={setEditUserModal}
          editUserData={selectedRowData}
          setPageLoader={setPageLoader}
        />
      )}
      {deleteUserModal && (
        <DeleteSmsUserModal
          setDeleteUserModal={setDeleteUserModal}
          setPageLoader={setPageLoader}
          selectedRowData={selectedRowData}
        />
      )}
      <Backdrop className="!z-[99999999999]" open={loader}>
        <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
      </Backdrop>
      <div className="h-[calc(100vh-125px)] w-[calc(100vw-118px)] overflow-x-auto overflow-y-hidden  rounded-md bg-[#fff]">
        <FadeIn className="pt-3">
          <TableTopBar
            title={"SMS Users"}
            button={true}
            buttonText={"Add SMS User"}
            permission={"sms.sms_users.get"}
            setSearchText={setSearchText}
            handleSearch={handleSearch}
            onHandleSort={onHandleSort}
            handleKeyPress={handleKeyPress}
            setHandleKeyPress={setHandleKeyPress}
            actionHandler={actionHandler}
          />

          <Table
            tableTitles={tableTitles}
            setTableTitles={setTableTitles}
            tableData={tableData}
            actionHandler={actionHandler}
            resultNotFound={resultNotFound}
            onHandleSort={onHandleSort}
            width={"w-auto overflow-auto"}
            scrollHeight={"h-[calc(100vh-280px)] w-full overflow-y-auto"}
          />

          {!resultNotFound && tableData?.length > 0 && (
            <PaginationButtons
              pageNumber={pageNumber}
              handleChange={handleChange}
              pageCount={pageCount}
              perPage={perPage}
              handlePerPage={handlePerPage}
            />
          )}
        </FadeIn>
      </div>
    </div>
  );
}

export default SmsUsers;
