import jwtDecode from "jwt-decode";
import { getWebInstrumentations, initializeFaro, InternalLoggerLevel } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { trace, context } from "@opentelemetry/api";
import { getENVData } from "../config/environment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const FaroInstance = (props: any) => {
  const accessToken = useSelector((state: any) => state.auth.accessToken);
  const env: any = getENVData();
  let token: any;
  var faro: any = '';
  useEffect(() => {
    if (env?.config?.faro?.url) {
      if (accessToken) {
        token = jwtDecode(accessToken);
      }
      var faro = initializeFaro({
        url: env?.config.faro.url,
        apiKey: env.config.faro.apiKey,
        app: {
          name: env.config.faro.app.name,
          version: env.config.faro.app.version,
        },
        user: {
          username: token?.name || "",
          email: token?.email || "",
        },
        internalLoggerLevel: InternalLoggerLevel.INFO,
        instrumentations: [...getWebInstrumentations(),
        new TracingInstrumentation()],
      });

      faro?.api?.initOTEL(trace, context);
      const tracer = trace.getTracer("default");
      const span = tracer.startSpan("click");
      context.with(trace.setSpan(context.active(), span), () => {
        span.end();
      });
    }

  }, []);


  return props.children;
};
export default FaroInstance;