import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
  launchDate: {};
}

const initialState: CallState = {
  launchDate: {},
};

const date = createSlice({
  name: "launchDateTime", // Use a different name for the slice
  initialState,
  reducers: {
    dateList: (state, action: PayloadAction<any>) => {
      state.launchDate = action.payload;
      console.log("state.activeMenu", state.launchDate);
    },
  },
});

export const { dateList } = date.actions;
export default date.reducer;
