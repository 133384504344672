import React, { Children, useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import {
  CrossIcon,
  EditPencilIcon,
  EditPencilIcon2,
  TableSortIconDownArrow,
} from "../../utils/icons/defaultIcons";
import TableTopBar from "../modal/tableTopBar";
import Table from "../../atom/table";
import Loader from "../loader";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PaginationButtons from "../../atom/pagination";
import { Backdrop, CircularProgress } from "@mui/material";
import Notification from "../../atom/Notification";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import { setBreadcrumbs } from "../../toolkit/reducers/breadCrumbsSlice";
import ComponentMap from "../../atom/componentmap";
import DidRouter from "./didRouter";
import { getDidsList, release_DID } from "../../services/api";
import Modal from "../../atom/modal";
import ThemedButton from "../../atom/ThemedButton/themedButton";

function Did_Management() {
  const [manageState, setManageState] = useState<string>("");
  // const [tableData, setTableData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(25);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [releaseData, setreleaseData] = useState<any>([]);
  const [releaseModal, setReleaseModal] = useState<any>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "did",
      title: "DID Number",
      icon: <TableSortIconDownArrow />,
      type: "link",
      idname: "did_number",
      sort: { direction: "asc", active: true },
    },
    {
      key: "tenant_name",
      title: "Tenant",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "carrier",
      title: "Carrier",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "assigned_to",
      title: "Assigned",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "last_used",
      title: "Last Used",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "release",
      title: "",
      type: "link",
      idname: "release",
      class: "text-right",
    },

    // {
    //   key: "action_button",
    //   title: "",
    //   type: "actionIcon",
    //   class: "text-right pr-[40px]",
    //   icon: <EditPencilIcon />,
    //   action: {
    //     title: "Edit",
    //     command: "edit",
    //     action: "click",
    //   },
    // },
  ]);

  const [tableData, setTableData] = useState<any>([]);

  const [searchtext, setSearchText] = useState<any>("");
  const [sort, setSort] = useState<any>({
    key: "email",
    order: "ASC",
  });
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);
  const [purchaseDidModal, setPurchaseDidModal] = useState<boolean>(false);

  const onHandlepurchase = () => {
    setPurchaseDidModal(true);
    navigate(`purchase`, { replace: false });
  };

  const onHandleFetch: any = (options: {
    limit?: number;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    setLoader(true);
    getDidsList({
      limit: options?.limit || perPage,
      pageId: options?.pageNumber || pageNumber,
      sort_column: options?.sort_column || sort.key,
      sort_direction: options?.sort_direction || sort.order,
      searchtext:
        options?.searchtext === undefined
          ? searchtext
          : options?.searchtext === ""
          ? ""
          : options?.searchtext,
    }).then((result: any) => {
      const didData = result?.data?.items;
      if (!result.success || didData?.length === 0) {
        setLoader(false);
        setResultNotFound(true);
      } else {
        setResultNotFound(false);
        didData?.map((node: any) => {
          node.last_used = moment(new Date(node.last_used)).format(
            "MM/DD/YYYY, hh:mm A"
          );
        });
        didData?.map((item: any) => {
          return item.assigned_to === "Unassigned"
            ? (item.release = "Release")
            : "";
        });
      }
      setTableData(didData);
      setLoader(false);
      setPageCount(result?.data.total);
    });
  };

  const onHandleLink = (action: string, data: any, key: any) => {
    console.log("dataaaa", data);

    if (key === "did") {
      action = "lifecycle";
      navigate(`${data?.uuid}/${action}`, { replace: false });
    } else if (key === "release") {
      setReleaseModal(true);
      setreleaseData(data);
    }
  };

  const path = useLocation();
  // this function handles every button clicks for navigation :)
  const onhandleStateChange = (state: any, data: any) => {
    if (state === "Add DCID") {
      state = "add";
    }

    if (state === "Purchase DIDs") {
      state = "purchase";
    }
    console.log("statestate", state);
    navigate(`${state}`, { replace: false });
  };

  const actionHandler = (action: string, data: any, key: any) => {
    console.log("action", action, "data", data, "keyy", key);
    const actionsMap: any = {
      edit: onhandleStateChange,
      "Add DCID": onhandleStateChange,
      link: onHandleLink,
      "Purchase DIDs": onHandlepurchase,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data, key);
    } else {
      console.log("actionHandler Error");
    }
  };

  const handleSearch = (data: string) => {
    setPageNumber(1);
    setSearchText(data);
    onHandleFetch({ searchtext: data, pageNumber: 1 });
  };

  const handleChange = (pNUm: number) => {
    get_DIDs_List(pNUm);
    setPageNumber(pNUm);
  };

  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, pageNumber: 1 });
  };

  const activeBreadCrumbs = useSelector(
    (state: any) => state.BreadCrumbsList.brudCrubName
  );
  const TeanantName = useSelector(
    (state: any) => state.BreadCrumbsList.tenantName
  );

  const get_DIDs_List = (pgNum: number) => {
    setPageNumber(pgNum);
    onHandleFetch({ pageNumber: pgNum });
  };

  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_direction: orderBy });
  };

  const onCloseModal = () => {
    setReleaseModal(false);
  };

  const onHandleRelease = () => {
    console.log("tbledata", releaseData);
    setButtonLoader(true);
    release_DID(releaseData?.uuid).then((result: any) => {
      if (!result.success) {
        Notification(
          "Failed",
          `${result?.message ? result?.message : "Oops! Something went wrong"}`,
          "danger"
        );
        setButtonLoader(false);
      } else {
        setButtonLoader(false);
        Notification("Success", `DID released successfully`, "success");
        get_DIDs_List(1);
        setReleaseModal(false);
      }
    });
  };

  useEffect(() => {
    get_DIDs_List(1);
  }, [path?.pathname]);

  return (
    <div>
      {releaseModal && (
        <Modal title={"Release"} closeEvent={onCloseModal}>
          Are you sure you want to release <b>{releaseData?.did} ?</b>
          <div className="text-right w-full  mt-8">
            <ThemedButton
              children={"Cancel"}
              icon="custom"
              theme="secondary"
              click={onCloseModal}
            />
            <ThemedButton
              children={"Release"}
              icon="custom"
              theme="primary"
              click={onHandleRelease}
              loading={buttonLoader}
            />
          </div>
        </Modal>
      )}
      <div className="h-full py-3 flex relative  rounded-md">
        <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop>
        <div
          className={`h-[calc(100vh-125px)] ${
            purchaseDidModal ||
            (path.pathname === "app/dids/purchase" && "basis-[65%]")
          }  overflow-x-auto overflow-y-hidden rounded-md bg-[#fff]`}
        >
          <FadeIn>
            <div className="pt-3">
              <TableTopBar
                title={"DID Management"}
                button={true}
                manageState={manageState}
                setManageState={setManageState}
                onhandleStateChange={onhandleStateChange}
                permission={"provision.users.post"}
                buttonText={"Purchase DIDs"}
                search={true}
                searchtext={searchtext}
                setSearchText={setSearchText}
                handleSearch={handleSearch}
                handleKeyPress={handleKeyPress}
                setHandleKeyPress={setHandleKeyPress}
                actionHandler={actionHandler}
              />
            </div>

            <Table
              tableTitles={tableTitles}
              tableData={tableData}
              setTableData={setTableData}
              actionHandler={actionHandler}
              setTableTitles={setTableTitles}
              button={true}
              buttonIndex={4}
              buttonText1={"manage"}
              width={"w-[calc(100vw-122px)] overflow-auto"}
              scrollHeight={"h-[calc(100vh-280px)] overflow-y-auto"}
              manageState={manageState}
              setManageState={setManageState}
              onhandleStateChange={onhandleStateChange}
              permission={"provision.users.put"}
              actions={true}
              onHandleSort={onHandleSort}
              resultNotFound={resultNotFound}
            />
            {!resultNotFound && tableData.length > 0 && (
              <PaginationButtons
                pageNumber={pageNumber}
                handleChange={handleChange}
                pageCount={pageCount}
                perPage={perPage}
                handlePerPage={handlePerPage}
              />
            )}
          </FadeIn>
        </div>
        <DidRouter />
      </div>
    </div>
  );
}

export default Did_Management;
