/* eslint-disable jsx-a11y/alt-text */
import { useRef } from "react";
import UploadIcon from "@mui/icons-material/Upload";

const Upload = (props) => {
  const { node, data, change } = props;
  console.log("node", node, data);
  const hiddenFileInput = useRef(null);

  async function handleChange(e) {
    if (e.target.files[0].size > 5000000) {
    } else {
      let updateData = [...data];
      let filter;

      let image_as_files = e.target.files[0];
      console.log("SIZE", image_as_files, image_as_files.size);
      let file_size = image_as_files.size;

      let formData = new FormData();
      formData.append("document", image_as_files);
      let url;
      let file = e.target.files[0];
      url = URL.createObjectURL(file);
      console.log(url, "thisisurl");
      filter = updateData.filter((n) => {
        return n.name === node.name;
      });

      if (filter[0]) {
        filter[0].value = url;
        filter[0].size = file_size;
      }
      change([...updateData]);
    }
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <>
      {node.value === "" || node.value === null ? (
        <div className="input  relative">
          <div
            className="bg-primary-alpha-20 border cursor-pointer hover:bg-primary-alpha-40 -mt-[10px] w-[calc(100%-16px)] text-center pt-3.5 h-[56px] absolute border-primary-alpha-40 rounded-md"
            onClick={handleClick}
          >
            <UploadIcon /> {node.title}
          </div>
        </div>
      ) : (
        <div className="input  relative">
          <div
            className="cursor-pointer border border-primary-alpha-40 -mt-[10px] w-[calc(100%-16px)] flex justify-center overflow-hidden h-[56px] absolute rounded-md"
            onClick={handleClick}
          >
            <img src={node.value} className="max-w-full max-h-full m-1"></img>
          </div>
        </div>
      )}
      <input
        type="file"
        hidden
        accept={node.accept}
        onChange={(e) => handleChange(e)}
        ref={hiddenFileInput}
      />
    </>
  );
};

export default Upload;
