const transalation = {
    AddressBook: "Address Book",
    CarrierManager: "Carrier Manager",
    ControlManager: "Control Manager",
    Dashboard: "Dashboard",
    Receptionist: "Receptionist",
    SystemManager: "System Manager",
    AccountManager: "Account Manager",
    RatesManager: "Rates Manager",
    NumberManager: "Number Manager",
    Branding: "Branding",
    Users: "Users",
    Groups: "Groups",
    MainNumber: "Numbers",
    CallLogs: "Call Logs",
    Devices: "Devices",
    VoiceMail: "Voice Mail",
    IVR: "IVR",
    NotificationManager: "Notification Manager",
    Settings: "Settings",
    Theme: "Theme",
    Language: "Language",
    SignOut: "Sign Out",
    CompanyName: "Company Name",
    AddressLine1: "Address Line 1",
    AddressLine2: "Address Line 2",
    EMail: "E-mail",
    Cancel: "Cancel",
    Save: "Save",
    Ok: "Ok",
    AddNew_: "Add New {{key}}",
    No_Found: "No {{key}}",
    AccountName: "Account Name",
    Enable: "Enable",
    Disable: "Disable",
    Actions: "Actions",
    Invoices: "Invoices",
    AssignNumber: "Assign Number",
    AddCredit: "Add Credit",
    RemoveUser: "Remove User",
    AddNewExtension: "Add New Extension",
    Unassign: "Unassign",
    Getstartedbycreatinganew: "Get started by creating a new",
    PhoneNumbers: "Phone Numbers",
    Label: "Label",
    No: "No",
    Update: "Update",
    Assign: "Assign",
    Date: "Date",
    Time: "Time",
    From: "From",
    To: "To",
    Duration: "Duration",
    Search: "Search",
    Numbers: 'Number',
    UpdatedSuccessfully: "{{key}} Updated Successfully",
    ErrorErrorInUpdating: "Error! Error In Updating {{key}}",

    Control: {
        SomethingWentWrongPleaseTryAgain: "Something went wrong,Please try again",
        Users: {
            FirstName: "First Name",
            LastName: "Last Name",
            Extensions: "Extensions",
            UserRole: "User roles",
            MainExtensionNumber: "Main Extension Number",
            PhoneNumbers: "Phone Numbers",
            Devices: "Devices",
            Features: "Features",
            AddToCompanyDirectory: "Add to company directory",
            CallerIDNumber: "Caller ID Number",
            PleaseSelectCallerId: "Please Select  Caller Id",
            CallForwarding: "Call Forwarding",
            HotDesking: "Hot Desking",
            Voicemails: "Voicemails",
            MusicOnHold: "Music on Hold",
            FindMeFollowMe: "Find Me Follow Me",
            FaxBox: "Fax Box",
            MissedCallAlert: "Missed Call Alert",
            LocalDialing: "Local Dialing",
            SelectCallerId: "Select caller id",
            ActiveFeatures: "Active Features",
            Password: "Password",
            AddNewDevice: "Add New Device",
            Email: "Email",
            PleaseAssignNumberFirst: "Please Assign Number First",
            AddToCountryDirectory: "Add to company directory",
            CreateDeviceAutomatically: "Create device automatically",
            ExtensionAddedSuccessfully: "Extension Added Successfully ",
            ErrorWhileAddingExtensionPleaseTryAgain: "Error while Adding  Extension,please try again",
            ExtensionAlreadyExist: "Extension already Exist",
            ExtensionUpdated: "Extension Updated",
            ExtensionRemovedSuccessfully: "Extension Removed Successfully",
            ErrorWhileRemovingExtensionPleaseTryAgain: "Error while removing  Extension,please try again",
            ExtensionsMustContainExactlyDigits: "Extensions must contain exactly 4 digits.",
            InvalidFormat: "Invalid Format"

        },
        Groups: {
            Members: "Members",
            Extensions: "Extensions",
            PhoneNumbers: "Phone Numbers",
            Features: "Features",
            RingBack: "Ring Back",
            NextCall: "Next call",
            CallerIDPrepend: "Caller ID Prepend",
            AddNewGroup: "Add New Group",
            Distribute: "Distribute",
            ManageUser: "Manage User",
            Group: "Group",
            GroupName: "Group Name",
            Unassign: "Unassign",
            AddfromSpare: "Add From Spare",
            Spare: "Spare",
            RemoveGroup: "Remove Group",
            Search: "Search",

            GroupFeatures: {
                callerIdPrependUpdatedSuccesfully: "Caller Id Prepend Updated Succesfully",
                ErrorInUpdatingCallerIdPrepend: "Error In Updating Caller Id Prepend"
            }
        },
        CallLogs: {
            All: "All",
            Incoming: "Incoming",
            Outgoing: "Outgoing",
            MissedCall: "MissedCall",
            Extension: "Extension",
            Status: "Status",
            CallRecordings: "Recordings",
            Start: "Start",
            End: "End",
            Filter: "Filter",

        },
        Devices: {
            Type: "Type",
            Enable: "Enable",
            Name: "Name",
            User: "User",
            Number: "Number",
            AddFromSpare: "Add From Spare",
            ChooseDevice: "Choose Device",
            PleaseSelectADevice: "Please Select a Device",
            DeviceUpdatedSuccessfully: "Device Updated Successfully ",
            ErrorInUpdatingDevice: "Error In Updating Device"




        },
        VoiceMail: {
            Name: "Name",
            User: "User",
            Number: "Number"


        },
        Number: {
            labelUpdateSuccessMsg: "label updated succsessfully",
            TwentyFourHours: "24 hours Open Office",
            CustomHours: "Custom Office Hours",
            SuccsesAssign: "Number assigned succsessfully",
            SuccsesHoursUpdate: "Office Hours Updated succsessfully",
            SuccsesHoursAdd: "Office Hours added succsessfully",
            SuccsesHolidaysUpdate: "Office Holidays updated succsessfully",
            SuccsesHolidaysAdd: "Office Holidays added succsessfully",
            OfficeHours: "Office Hours Strategy",
            OfficeHolidays: "Office Holidays",
            IncomingCallHandling: "Incoming Call Handling",
            Open: "Open",
            Closed: "Closed",
            AddHolidays: "Add Holidays",
            Single: "Single day",
            Range: "Date Range",
            Advanced: "Advanced",
            Month: "Month",
            Day: "Day",
            Week: "Week",
            HolidayName: "Holiday Name",
            From: "From",
            To: "To",
            OpenHours: "Open Hours",
            AfterHours: "After Hours",
            HolidayHours: "Holiday Hours",
            ClosedHours: "Closed Hours",
            Error: "Error",
            AlreadyExist: "Number Already Exist."
        },
        PhoneNumbers: {
            AddFromSpare: "Add From Spare"
        },
        Features: {
            RequireKeyPress: "Require key press",
            ForwardDirectCallOnly: "Forward direct call only",
            KeepYourCallerId: "Keep your caller-id",
            HotdeskId: "Hotdesk Id",
            DoesItRequireAPin: 'Does it require a PIN',
            AllowLoginToMultipleDevices: 'Allow login to multiple devices',
            PleaseAssignNumberFirst: "Please assign number first",
            EnableVoiceMailToEmail: "Enable Voice mail to email",
            CallerIdNumberUpdatedSuccessfully: 'Caller Id Number Updated Successfully',
            ErrorInUpdatingCallerIdNumber: 'Error In Updating Caller Id Number',
            Failed: "Failed",
            UpdatedSuccessfully: "Updated Successfully",
            OnOff: "On/Off",
            FaxToEmail: 'Fax To Email',
            EmailToFax: 'Email to Fax',
            FaxToEmailIsRequired: "Fax To Email is required",
            EmailToFaxIsRequired: "Email to Fax is required",
            CallForwardingUpdatedSuccessfully: 'Call Forwarding Updated Successfully'


        },
        Queue: {
            Agents: "Agents",
            Distribution: "Distribution",
            MusicOnHold: "Music on Hold",
            Name: "Name",
            Extension: "Extension",
            Skill: "Skill",
            Priority: "Priority",
            Number: "Number"
        }
    },
    Number: {
        All: "All",
        Number: "Number",
        Country: "Country",
        Resellers: "Reseller(s)",
        Client: "Client",
        Status: "Status",
        AddNewNumber: "Add New Number",
        Search: "Search",
        Unassign: "UNASSIGN",
        Remove: "REMOVE",
        AvailableNumber: "Available Number",
        SpareNumber: "Spare Number",
        SelectCountry: "Select Country",
        Assign: "ASSIGN",
        Cancel: "CANCEL",
        Add: "ADD",
        Buy: "BUY",
        Error: "Error",
        EnterNumber: " Enter Number",
        ErrorCountryRequired: "Country is required",
        ErrorNumberRequired: "Number is Required.",
        Success: "Success",
        NotificationNumberAdd: "Number Added Successfully",
        NotifiactionNumberbought: "Number Bought Succesfully",
        AssignNumber: "Assign Number",
        UnassignModalData: "Are you sure want to Unassign Number ?",
        RemoveModalData: "Are you sure want to Remove Number ?",
    },
    Brand: {
        CompanyName: "Company Name",
        AddressLine1: "Address Line 1",
        AddressLine2: "Address Line 2",
        Email: "E-mail",
        Cancel: "Cancel",
        Save: "Save",
        Logo: "Logo",
        FileTooltip: "File size should be less than 5MB.",
        ErrorUpload: "Please upload an Image.",
        ErrorCompanyName: "Please enter Company Name.",
        ErrorAddress: "Please enter Address.",
        ErrorEmail: "Please Enter Email."
    },
    Notification: {
        Subject: "Subject",
        Message: "Message",
        Clear: "Clear",
        Submit: "Submit",
    },
    Rates: {
        UploadRates: "Upload Rates",
        AddNewCountry: "ADD NEW COUNTRY",
        CountryName: "Country Name",
        CountryCode: "Country Code",
        Rate: "Rate",
        Actions: "Actions",
        ADD: "Add",
        SelectCountry: "Select Country",
        Uploadordropafilerighthere: "Upload or drop a file right here",
        Successfullyuploaded: " Successfully Uploaded",
        UnsupportedFileError: "Unsupported file, Please upload a file with .CSV extension",
        DownloadsampleSheet: "Download sample Sheet",
        AddRateSheet: "Add new Ratesheet",
        EnterNewRatesheet: "Enter Rate Sheet Name",
        InputKeyword: "Input keyword and press enter",
        RateDelete: "Rate has been deleted Successfully",
        AddRate: "Rate has been added Successfully",
        UpdateRate: "Rate has been updated Successfully",
        AddedRateSheet: "Ratesheet has been added Successfully",
        DownloadRateSheet: "Download Ratesheet",
        DeleteRateSheet: " Delete Ratesheet",
        RateSheetDeleted: "Ratesheet has been deleted Successfully",
        DeleteRate: "Delete Rate",
        DeleteRateModalData: "Are you sure you want to delete Rate?",
        DeleteRatesheetModalData: "Are you sure you want to delete Ratesheet?",
        Delete: "Delete"


    },
    Carrier: {
        Name: "Name",
        IPAddressDomain: "IP Address / Domain",
        Prefix: "Prefix",
        Port: "Port",
        Priority: "Priority",
        UserName: "User Name",
        Password: "Password",
        ChooseCountries: "Choose Countries",
        AllCountries: "All Countries",
        EnableFax: "Enable Fax",
        SetSIPCustomHeaders: "Set SIP Custom Headers",
        CallerIdType: "Caller ID Type",
        ErrorPriority: "Priority is required",
        ErrorPassword: "Password is required",
        ErrorUserName: "User Name is required",
        ErrorPort: "Port is required",
        ErrorPrefix: "Prefix is required",
        ErrorIPAddressDomain: "Valid IP Address / Domain is required",
        ErrorName: "Name is required",
        SuccessfullMsg: "Carrier Added successfully",
        ErrorMsg: "Oops , something went wrong !",
        UpdateMsg: "Carrier Updated successfully",
        Add: "Add New Carrier",
        Remove: "Remove",
        Search: "Search",
        Cancel: "Cancel",
        Update: "Update",
        Save: "Save",
        EmptyMsg: "No Carriers",
        GetStartedMsg: "Get started by creating a new Carrier.",
        TryAgainMsg: "Please try again later.",
        RemoveMsg: "Are you sure want to remove",
    },
    DashBoard: {
        Users: "Users",
        Devices: "Devices",
        Numbers: "Numbers",
        Credits: "Credits",
        TotalCalls: "Total Calls",
        ActivityLog: "Activity Log",
        Number: "Number",
        Who: "Who",
        Where: "Where",
        CallCharges: "Total Call Charges",
        MinutesUsed: "Total Minutes Used",
        IncomingCalls: "Incoming Calls",
        MissedCalls: "Missed Calls",
        OutgoingCalls: "Outgoing Calls",
        Calls: " Calls",
        Addusers: "Add Users",
        FirstName: "First Name",
        LastName: "Last Name",
        Email: "E-mail",
        Password: "Password",
        MainExtensionNumber: "Main Extension Number",
        CreateDeviceAutomatically: "Create Device Automatically",
        AddSipDevice: "Add SIP Device",
        AddCellDevice: "Add CELL Device",
        Name: "Name",
        AssignedTo: "Assigned to",
        notifyUnregister: "Notify on unregister(not recommended for mobile app users).",
        autoProvision: "Auto provision",
        DeviceName: "Device Name",
        useSystemVoicemail: "Use System Voicemail.",
        BuyNumbers: "Buy Numbers",
        addCredit: "Add Credit",
        Amount: "Amount",
    },
    Account: {
        AccountName: "Account Name",
        workspaceURL: "Workspace URL",
        workspaceInfo: "",
        workspaceNote: "",
        ErrorName: "Please Enter Name",
        Email: "Email",
        ErrorEmail: "Please Enter Valid Email",
        FirstName: "First Name",
        ErrorFirstName: "Please Enter First Name",
        LastName: "Last Name",
        ErrorLastName: "Please Enter Last Name",
        ContactNumber: "Contact Number",
        ErrorContact: "Please Enter Valid Contact Number",
        ChooseTimezone: "Choose Timezone",
        ErrorTimeZone: "Please Choose a Timezone",
        Address: "Address",
        ErrorAddress: "Please Enter Address",
        SuccessfullMsg: "Account Added successfully",
        ErrorMsg: "Oops , something went wrong !",
        UpdateMsg: "Account Updated successfully",
        AddNewAccount: "Add New Account",
        Actions: "Actions",
        RemoveAccount: "Remove Account",
        Reseller: "Reseller",
        Account: "Account",
        Plan: "Plan",
        Features: "Features",
        GetStartedMsg: "Get started by creating a new Account.",
        TryAgainMsg: "Please try again later.",
        EmptyMsg: "No Accounts",
        ErrorCarrier: "Please Select a Carrier"
    }

}
export default transalation;